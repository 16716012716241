<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useLayout } from '@/layout/composables/layout';
import { useRouter } from 'vue-router';
import { authStore } from '@/store/auth.module';
import { connectWebSocket } from '@/service/SocketService';
import { useToast } from 'primevue/usetoast';

const toast = useToast();

// const { layoutConfig, onMenuToggle } = useLayout();

const { onMenuToggle } = useLayout();

const usertore = authStore();
const { getUser, isLoggedIn } = storeToRefs(usertore);
const user = ref(getUser);

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const router = useRouter();
const webSocket = ref(null);

const notify = (title, message) => {
	if (Notification.permission === 'granted') {
		// Check whether notification permissions have already been granted;
		// if so, create a notification
		new Notification(title, {
			body: `${message} - ${new Date().toLocaleTimeString()}`,
			dir: 'auto',
			lang: 'en',
			renotify: true,
			tag: 'Job'
		});
	} else if (Notification.permission !== 'denied') {
		// We need to ask the user for permission
		Notification.requestPermission().then((permission) => {
			// If the user accepts, let's create a notification
			if (permission === 'granted') {
				new Notification(title, {
					body: `${message} - ${new Date().toLocaleTimeString()}`,
					dir: 'auto',
					lang: 'en',
					renotify: true,
					tag: 'Job'
				});
			}
		});
	}
};

const connectSocketIO = () => {
	webSocket.value = connectWebSocket(user.value);
	webSocket.value.on('notification', function (msg, cb) {
		console.log(JSON.stringify(msg.data));
		notify(msg.data.title, msg.data.message);
		if (cb) cb();
	});
};

watch(isLoggedIn, (val) => {
	console.log('isLoggedIn', val);
	if (val) {
		connectSocketIO();
	} else {
		webSocket.value.disconnect();
	}
});

onMounted(() => {
	bindOutsideClickListener();
	if (isLoggedIn) {
		connectSocketIO();
	}

	if (Notification.permission !== 'denied') {
		// We need to ask the user for permission
		Notification.requestPermission().then((permission) => {
			// If the user accepts, let's create a notification
			if (permission === 'granted') {
				new Notification('Welcome to Elevate Tech Workforce', {
					body: `You will receive notifications for new jobs and issues - ${new Date().toLocaleTimeString()}`,
					dir: 'auto',
					lang: 'en',
					renotify: true,
					tag: 'Job'
				});
			} else {
				toast.add({
					severity: 'warn',
					summary: 'Warning',
					detail: 'You will not receive notifications for new jobs and issues on the browser. Please enable notifications in your browser settings.',
					life: 3000
				});
			}
		});
	}
});

onBeforeUnmount(() => {
	unbindOutsideClickListener();
});

const logoUrl = computed(() => {
	// return `layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.svg`;
	return `layout/images/logo.png`;
});

const onTopBarMenuButton = () => {
	topbarMenuActive.value = !topbarMenuActive.value;
};
const onSettingsClick = () => {
	usertore.logout();
	topbarMenuActive.value = false;
	router.push('/auth/login');
};
const topbarMenuClasses = computed(() => {
	return {
		'layout-topbar-menu-mobile-active': topbarMenuActive.value
	};
});

const bindOutsideClickListener = () => {
	if (!outsideClickListener.value) {
		outsideClickListener.value = (event) => {
			if (isOutsideClicked(event)) {
				topbarMenuActive.value = false;
			}
		};
		document.addEventListener('click', outsideClickListener.value);
	}
};
const unbindOutsideClickListener = () => {
	if (outsideClickListener.value) {
		document.removeEventListener('click', outsideClickListener);
		outsideClickListener.value = null;
	}
};
const isOutsideClicked = (event) => {
	if (!topbarMenuActive.value) return;

	const sidebarEl = document.querySelector('.layout-topbar-menu');
	const topbarEl = document.querySelector('.layout-topbar-menu-button');
	if (!sidebarEl || !topbarEl) return true;
	return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
</script>

<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img :src="logoUrl" alt="logo" />
		</router-link>

		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle()">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
			<i class="pi pi-ellipsis-v"></i>
		</button>

		<div class="layout-topbar-menu" :class="topbarMenuClasses">
			<!-- <button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">
				<i class="pi pi-calendar"></i>
				<span>Calendar</span>
			</button>
			<button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">
				<i class="pi pi-user"></i>
				<span>Profile</span>
			</button> -->
			<div class="p-link layout-topbar-button">
				<i class="pi pi-user"></i> <span style="display: block !important" class="ml-1 font-bold">Welcome {{ user ? user.name : '' }}</span>
			</div>
			<button @click="onSettingsClick()" class="p-link layout-topbar-button">
				<i class="pi pi-sign-out"></i>
				<span>Sign out</span>
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
