import { ref } from 'vue';
import { io } from 'socket.io-client';

const isConnected = ref(false);

const connectWebSocket = (user) => {
	const socketUrl = 'https://api-wf.elevate-tech.sg/socket/WorkForce';
	const completeSocketURL = socketUrl + `&token=${user.token}&user_id=${user.userObjectID}`;

	console.log(completeSocketURL);

	const socket = io(socketUrl, { query: { token: user.token, user_id: user.userObjectID } });

	// client-side
	socket.on('connect', () => {
		console.log(socket.id); // x8WIv7-mJelg7on_ALbx
		isConnected.value = true;
	});

	socket.on('disconnect', () => {
		console.log(socket.id); // undefined
		isConnected.value = false;
	});
	return socket;
};

export { connectWebSocket, isConnected };
