import { createRouter, createWebHashHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';

const router = createRouter({
	history: createWebHashHistory(),

	routes: [
		{
			path: '/',
			meta: {
				requiresAuth: true
			},
			component: AppLayout,
			children: [
				{
					path: '/',
					name: 'dashboard',
					component: () => import('@/views/Dashboard.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/contracts',
					name: 'contracts',
					component: () => import('@/views/Contracts.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/assets',
					name: 'assets',
					component: () => import('@/views/Assets.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/issues',
					name: 'issues',
					component: () => import('@/views/Issues.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/jobs',
					name: 'jobs',
					component: () => import('@/views/Jobs.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/team',
					name: 'team',
					component: () => import('@/views/Team.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/checklist',
					name: 'checklist',
					component: () => import('@/views/CheckLists.vue'),
					meta: {
						requiresAuth: true
					}
				}
			]
		},
		{
			path: '/pages/notfound',
			name: 'notfound',
			component: () => import('@/views/pages/NotFound.vue'),
			meta: {
				requiresAuth: false
			}
		},

		{
			path: '/auth/login',
			name: 'login',
			component: () => import('@/views/pages/auth/Login.vue'),
			meta: {
				requiresAuth: false
			}
		},
		{
			path: '/auth/access',
			name: 'accessDenied',
			component: () => import('@/views/pages/auth/Access.vue'),
			meta: {
				requiresAuth: false
			}
		},
		{
			path: '/auth/error',
			name: 'error',
			component: () => import('@/views/pages/auth/Error.vue'),
			meta: {
				requiresAuth: false
			}
		}
	]
});

router.beforeEach((to, from, next) => {
	const authRequired = to.matched.some((route) => route.meta.requiresAuth);
	const loggedIn = localStorage.getItem('user');
	// trying to access a restricted page + not logged in
	// redirect to login page
	if (authRequired && !loggedIn) {
		next('/auth/login');
	} else {
		next();
	}
});

export default router;
