

const pushService = {
    getPlatform() {
        const device = window.device;
        return device ? device.platform.toLowerCase() : 'web';
    },

    isCordova() {
        return typeof window !== 'undefined' && window.cordova;
    },
    async init() {
        if (!this.isCordova()) {
            console.warn("Push notifications are not supported in the web environment.");
            return;
        }else{
            // const { default: PushNotification } = await import('phonegap-plugin-push');
            // const push = PushNotification.init({
            //     android: {
            //         senderID: "YOUR_GCM_SENDER_ID"
            //     },
            //     ios: {
            //         alert: "true",
            //         badge: "true",
            //         sound: "true"
            //     }
            // });
    
            // push.on('registration', (data) => {
            //     console.log("Device registered:", data.registrationId);
            //     // Send the registration ID to your backend server to store and send push notifications
            // });
    
            // push.on('notification', (data) => {
            //     console.log("Notification received:", data);
            //     // Handle the notification
            // });
    
            // push.on('error', (e) => {
            //     console.error("Push notification error:", e);
            // });
        }

        
    }
};

export default pushService;
