<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import AppMenuItem from './AppMenuItem.vue';
import { authStore } from '@/store/auth.module';
const auth = authStore();
const { getUser } = storeToRefs(auth);
const user = ref(getUser);

const isTechnecian = ref(user.value.role == 'teamMember');

const model = ref([
	{
		label: 'Home',
		items: !isTechnecian.value
			? [
					{ label: 'Overview', icon: 'pi pi-fw pi-home', to: '/' },
					{ label: 'Contracts', icon: 'pi pi-fw pi-briefcase', to: '/contracts' },
					{ label: 'Assets', icon: 'pi pi-fw pi-box', to: '/assets' },
					{ label: 'Issues', icon: 'pi pi-fw pi-server', to: '/issues' },
					{ label: 'Jobs', icon: 'pi pi-fw pi-wrench', to: '/jobs' },
					{ label: 'Team', icon: 'pi pi-fw pi-users', to: '/team' },
					{ label: 'Checklist', icon: 'pi pi-fw pi-file-edit', to: '/checklist' }
			  ]
			: [
					{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
					{ label: 'Assets', icon: 'pi pi-fw pi-box', to: '/assets' },
					{ label: 'Jobs', icon: 'pi pi-fw pi-wrench', to: '/jobs' }
			  ]
	}
]);
</script>

<template>
	<ul class="layout-menu">
		<template v-for="(item, i) in model" :key="item">
			<app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
			<li v-if="item.separator" class="menu-separator"></li>
		</template>
	</ul>
</template>

<style lang="scss" scoped></style>
